import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: 'default-hackney',
    predictionPlace: {
      address: 'Hackney, London',
      bounds: new LatLngBounds(
        new LatLng(51.5777843, -0.0165272),
        new LatLng(51.5198144, -0.1043911)
      ),
    },
  },
  {
    id: 'default-london',
    predictionPlace: {
      address: 'London, UK',
      bounds: new LatLngBounds(
        new LatLng(51.67234324898703, 0.1482710335611201),
        new LatLng(51.38494012429096, -0.3514683384218145)
      ),
    },
  },
  {
    id: 'default-surrey',
    predictionPlace: {
      address: 'Surrey, UK',
      bounds: new LatLngBounds(
        new LatLng(51.47153282108686, 0.05821626141880546),
        new LatLng(51.07149644470977, -0.8489290531545528)
      ),
    },
  },
  {
    id: 'default-oxford',
    predictionPlace: {
      address: 'Oxford, UK',
      bounds: new LatLngBounds(
        new LatLng(51.79630057994381, -1.175176338546518),
        new LatLng(51.70453203727247, -1.3199993851725)
      ),
    },
  },
  {
    id: 'default-bristol',
    predictionPlace: {
      address: 'Bristol, UK',
      bounds: new LatLngBounds(
        new LatLng(51.54443262264671, -2.450902404174833),
        new LatLng(51.39254527703832, -2.730532637989797)
      ),
    },
  },
  {
    id: 'default-manchester',
    predictionPlace: {
      address: 'Manchester, UK',
      bounds: new LatLngBounds(
        new LatLng(53.54458793949853, -2.146828844526851),
        new LatLng(53.39990299768613, -2.300096904893769)
      ),
    },
  },
];
export default defaultLocations;
