import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { listingImage } from '../../config/configLayout';

const PER_PAGE = 24;
const { aspectRatio: aspectRatioRaw, variantPrefix = 'listing-card' } = listingImage;
const aspectRatio = 1 / eval(aspectRatioRaw);
const defaultListQueryParams = {
  perPage: PER_PAGE,
  include: ['images', 'currentStock'],
  'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
  ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
  ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
  'limit.images': 1,
};

export const FavoriteListingsPageApi = createApi({
  reducerPath: 'FavoriteListingsPageApi',
  tagTypes: ['FavoriteListings'],
  baseQuery: fakeBaseQuery(),
  endpoints: builder => ({
    listFavoriteListings: builder.query({
      queryFn: async (arg, queryApi, _extraOptions, _baseQuery) => {
        const { dispatch, extra: sdk } = queryApi; // eslint-disable-line no-unused-vars
        const { page = 1, ids = '' } = arg;
        const params = {
          ids,
          page,
          ...defaultListQueryParams,
        };
        const listingsResponse = await sdk.listings.query(params);

        const listings = denormalisedResponseEntities(listingsResponse);
        const pagination = listingsResponse.data.meta;

        const data = {
          listings,
          pagination,
        };
        return { data };
      },
      invalidatesTags: ['FavoriteListings'],
    }),
  }),
});

export const { useListFavoriteListingsQuery, reducer } = FavoriteListingsPageApi;
